import './App.css';
import HomeScreen from './containers/Home';

function App() {
  return (
    <div className="App">
      {window.location.origin}
      <HomeScreen/>
    </div>
  );
}

export default App;
